import React from 'react'
import classNames from 'classnames'
import { graphql } from 'gatsby'

import SEO from '../components/Seo'
import { Locale, Project } from '../types'
import Header from '../components/Header'

import styles from '../assets/styles/template.module.css'
import Footer from '../components/Footer'

type ProjectTemplateProps = {
  data: {
    project: Project
  }
  pageContext: {
    language: Locale
    intl: {
      messages: {}
    }
  }
  location: Location
}

const ProjectPage = ({
  data: { project },
  pageContext: { language },
  location,
}: ProjectTemplateProps) => {
  return (
    <div className="w-screen overflow-x-hidden bg-primary">
      <Header isLandingPage={false} location={location} locale={language} />
      <SEO
        description={project.seoDescription}
        lang="fi"
        title={project.title}
      />
      <div className="max-w-screen-15inchLaptop mx-auto overflow-x-hidden pb-40 lg:pb-64">
        <div
          className={styles.hero_backdrop}
          style={{
            backgroundImage: `url(${project.heroImage.file.url})`,
          }}
        />
        <div className="mx-auto w-10/12 lg:w-9/12 mt-10 lg:mt-16">
          <h1 className="uppercase text-5xl lg:text-6xl">{project.title}</h1>
          <p className="text-lg sm:text-2xl lg:text-3xl leading-normal lg:text-left mx-auto my-10 lg:my-16 lg:pb-2">
            {project.seoDescription}
          </p>
          <div className="lg:w-full gap-10 grid grid-cols-1 md:grid-cols-2">
            {project.portfolioPictures.map(picture => (
              <img
                src={picture.file.url}
                alt={picture.title}
                loading="lazy"
                className={classNames('object-contain', styles.portfolio_img)}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer locale={language} />
    </div>
  )
}

export default ProjectPage

export const ProjectQuery = graphql`
query ($slug: String!, $locale: String!) {
  project: contentfulProject(customUri: { eq: $slug }, node_locale: {eq: $locale}) {
      id
      node_locale
      title
      housingType
      seoDescription
      customUri
      heroImage {
        file {
          url
        }
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          quality: 100
        )
      }
      portfolioPictures {
        file {
          url
        }
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          quality: 100
        )
      }
    }
  }
`
